const renderArrow = (position, color, escape = true) => {
  let cursorSvg = ''

  const styles = `fill="none" stroke="${
    escape ? color.replace('#', '%23') : color
  }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"`

  switch (position) {
    case 'left':
      cursorSvg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve" width="128" height="128"><line ${styles} x1="64" y1="1.5" x2="2.2" y2="63.2"/><line ${styles} x1="2.2" y1="63.2" x2="64" y2="125"/></svg>`
      break
    case 'right':
      cursorSvg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve" width="128" height="128"><line ${styles} x1="64" y1="1.5" x2="125.8" y2="63.2"/><line ${styles} x1="125.8" y1="63.2" x2="64" y2="125"/></svg>`
      break
    case 'down':
      cursorSvg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve" width="128" height="128"><line ${styles} x1="2.2" y1="63.2" x2="64" y2="125"/><line ${styles} x1="64" y1="125" x2="125.7" y2="63.2"/></svg>`
      break
    case 'center':
    default:
      cursorSvg = `<svg version="1.1" id="_x31__DESIGN_2D_Lines.2D" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve" width="128" height="128"><g id="LWPOLYLINE"><path ${styles} d="M46,82.8V11.9c0-5.4,4.1-10,9.4-10.6c5.9-0.7,11.2,3.5,11.8,9.4l4.3,36.6"/></g><g id="ELLIPSE"><path ${styles} d="M6.7,75.6c25.6,37.8,67.4,59.4,93.3,48.2c19.4-8.4,24.9-33.4,13.9-62.9"/></g><g id="ELLIPSE_1_"><path ${styles} d="M46,82.8C34.3,72.7,18.1,68.1,10,72.6c-1.4,0.8-2.5,1.8-3.3,3.1"/></g><g id="ELLIPSE_2_"><path ${styles} d="M113.9,60.9C101.7,55,87.3,50.4,71.6,47.3"/></g></svg>`
      break
  }

  return cursorSvg
}

export default renderArrow
