import React from 'react'

const Footer = ({ siteTitle }) => (
  <footer>
    <div>
      <ul>
        <li>
          <a
            href="/images/pdf_booklet.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            studio profile
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/brandt_haferd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            instagram
          </a>
        </li>
      </ul>
    </div>
    <div>
      <ul>
        <li className="studio-email">
          <a href="mailto:studio@brandthaferd.com">studio@brandthaferd.com</a>
          <br />
        </li>
        <li>
          <a
            href="https://goo.gl/maps/hS1eZn34iY32"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>345 E. 104th St.</span>
            <br />
            <span>3rd Floor</span>
            <br />
            <span>New York, NY 10029</span>
          </a>
        </li>
      </ul>
    </div>
    <div>
      <ul>
        <li>
          <a
            href="https://mailchi.mp/f4f350f67ae9/seasonal-update-from-brandt-haferd"
            target="_blank"
            rel="noopener noreferrer"
          >
            Most Recent Newsletter
          </a>
        </li>
        <li>
          <a
            href="https://instagram.us12.list-manage.com/subscribe?u=8a3a79a1fde199f3eede6cd22&id=40acce20dd"
            target="_blank"
            rel="noopener noreferrer"
          >
            Newsletter Sign-up
          </a>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
