import React from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'

import Layout from '../components/layout'
import Slider from '../components/slider'
import Footer from '../components/footer'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    let showSplash = true
    if (
      this.props.location.state &&
      typeof this.props.location.state.showSplash !== 'undefined'
    ) {
      showSplash = this.props.location.state.showSplash

      // Hack to clear history state
      navigate('/', { state: {}, replace: true })
    }

    let activeSlideIndex = 0
    if (
      this.props.location.state &&
      typeof this.props.location.state.activeSlide !== 'undefined'
    ) {
      activeSlideIndex = this.props.location.state.activeSlide
    }

    this.state = {
      showSplash: showSplash,
      activeSlideIndex,
    }

    this._onHideSplash = this._onHideSplash.bind(this)
    this._onShowSplash = this._onShowSplash.bind(this)
  }

  _onHideSplash = () => {
    this.setState({ showSplash: false })
  }

  _onShowSplash = () => {
    this.setState({ showSplash: true, activeSlideIndex: 0 })
  }

  _onNavigatedToActiveSlide = () => {
    this.setState({ activeSlideIndex: null })
  }

  render() {
    const { showSplash, activeSlideIndex } = this.state

    return (
      <Layout>
        <StaticQuery
          query={graphql`
            {
              allSlidesJson(sort: { fields: order, order: ASC }) {
                edges {
                  node {
                    title
                    image
                    mobile_image
                    dark_background
                    title_color
                    title_mobile_color
                    pdf_page
                    internal_url
                    url
                    is_bio
                  }
                }
              }
            }
          `}
          render={data => (
            <div className="main-container">
              {showSplash ? (
                <section
                  className="splash-landing"
                  onClick={this._onHideSplash.bind(this)}
                >
                  <div className="down-arrow-container">
                    <img
                      src="/images/cursors/chevron_down_black.svg"
                      alt=""
                      className="down-arrow"
                    />
                  </div>
                </section>
              ) : (
                <Slider
                  data={data.allSlidesJson}
                  showSplash={this._onShowSplash}
                  activeSlide={activeSlideIndex}
                  onNavigatedToActiveSlide={this._onNavigatedToActiveSlide}
                ></Slider>
              )}
              <Footer />
            </div>
          )}
        />
      </Layout>
    )
  }
}

export default IndexPage
