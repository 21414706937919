import React from 'react'
import SlickSlider from 'react-slick'
import Modal from 'react-responsive-modal'
import { navigate } from 'gatsby'

import '../styles/bio.less'

import renderArrow from '../utils/cursor'

const MINIMUM_DESKTOP_WIDTH = 768

class Slider extends React.Component {
  constructor(props) {
    super(props)

    this.sliderSettings = {
      dots: false,
      fade: true,
      cssEase: 'linear',
      infinite: true,
      draggable: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: false,
      lazyLoad: 'progressive',
      beforeChange: this._onBeforeChange,
    }

    let currentSlide = this.props.data.edges[0].node

    this.state = {
      side: '',
      width: null,
      open: false,
      dark: currentSlide.dark_background,
      currentSlide,
    }
  }

  _onBackToTheStart = e => {
    e.stopPropagation()
    this.slider.slickGoTo(0)

    this.props.showSplash()
  }

  _onBeforeChange = (current, next) => {
    const slides = this.props.data.edges
    const slide = slides[next].node

    setTimeout(() => {
      this.setState({
        currentSlide: slide,
        dark: slide.dark_background,
      })
    }, 10)
  }

  _onOpenModal = () => {
    this.setState({ open: true })
  }

  _onCloseModal = () => {
    this.setState({ open: false })
  }

  _updateDimensions = () => {
    const w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth

    this.setState({ width })
  }

  // Show the cursor chevrons to the left/right or pointer depending on the position of the mouse
  _calculateCursorPosition = e => {
    const w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth

    const thirdWidth = Math.floor(width / 3)

    if (e.pageX < thirdWidth) {
      this.setState({ side: 'left' })
    } else if (e.pageX > thirdWidth && e.pageX < thirdWidth * 2) {
      this.setState({ side: 'center' })
    } else if (e.pageX > thirdWidth * 2) {
      this.setState({ side: 'right' })
    }
  }

  _onMouseMove = e => {
    const w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth

    const thirdWidth = Math.floor(width / 3)

    if (e.pageX < thirdWidth) {
      this.setState({ side: 'left' })
    } else if (e.pageX > thirdWidth && e.pageX < thirdWidth * 2) {
      this.setState({ side: 'center' })
    } else if (e.pageX > thirdWidth * 2) {
      this.setState({ side: 'right' })
    }
  }

  _onMouseClick = e => {
    e.preventDefault()

    this._updateDimensions()
    this._calculateCursorPosition(e)

    let { side, currentSlide, width } = this.state

    if (!e.pageX) {
      return
    }

    switch (side) {
      case 'left':
        this.slider.slickPrev()
        break
      case 'right':
        this.slider.slickNext()
        break
      default:
        if (currentSlide.url && currentSlide.url !== '') {
          if (currentSlide.internal_url) {
            navigate(currentSlide.url)
          } else {
            window.open(currentSlide.url, '_blank')
          }
        } else if (width >= 600) {
          this._onOpenModal()
        }

        break
    }
  }

  renderDownArrow = color => {
    return renderArrow('down', color, false)
  }

  componentDidMount = () => {
    if (this.props.activeSlide) {
      this.slider.slickGoTo(this.props.activeSlide)
      this.props.onNavigatedToActiveSlide()
    }

    setTimeout(() => {
      this._updateDimensions()
    }, 10)
    window.addEventListener('resize', this._updateDimensions)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this._updateDimensions)
  }

  render() {
    const { side, open, currentSlide, width } = this.state
    const slides = this.props.data.edges
    const pdfPageIndex = currentSlide.pdf_page

    const titleColor =
      width > MINIMUM_DESKTOP_WIDTH
        ? currentSlide.title_color
        : currentSlide.title_mobile_color

    return (
      <div>
        <div
          onClick={this._onMouseClick}
          onMouseMove={this._onMouseMove}
          className="slider-container"
          style={{
            cursor: `url('data:image/svg+xml;utf8,${renderArrow(
              side,
              titleColor
            )}') 24 24, auto`,
          }}
        >
          <div className="title-container">
            <h1
              className="title"
              style={{
                color: titleColor,
              }}
              onClick={this._onBackToTheStart}
            >
              BRANDT : HAFERD
            </h1>
          </div>
          <SlickSlider
            ref={slider => (this.slider = slider)}
            {...this.sliderSettings}
          >
            {slides.map((img, index) => {
              return (
                <div className="a-slide" key={index}>
                  <div
                    className={`image desktop-image ${img.node.is_bio ? 'bio-container' : ''}`}
                    style={{
                      backgroundImage: 'url(' + img.node.image + ')',
                    }}
                  >
                    {img.node.is_bio ? (
                      <article>
                        <p>K Brandt Knapp & Jerome W Haferd,</p>
                        <p>collaborators since 2008</p>
                        <br />
                        <p>
                          BRANDT : HAFERD is a Harlem-based architecture and design studio led
                          by Jerome W Haferd & K Brandt Knapp. Their body of work includes
                          academic research and a range of built projects - from the domestic to
                          the workplace to the urban. Performance and Play, Abstract vs. Built
                          Form, Nature and Territory are some of the interests explored in the
                          practice.
                        </p>
                        <br />
                        <p>
                          Haferd (M.Arch, Yale) has worked in the offices of OMA and Bernard
                          Tschumi Architects, where for years he was involved in numerous
                          projects in the U.S., Asia, and Europe. He teaches at Columbia GSAPP,
                          NJIT and Pratt Institute.
                        </p>
                        <br />
                        <p>
                          Knapp (M.Arch, Yale) has worked in the offices of Joeb Moore &
                          Partners, and Richard Meier & Partners, where she designed and led
                          institutional and residential projects in the U.S. and abroad. She
                          teaches at Columbia GSAPP, PennDesign, NJIT and Pratt Institute.{' '}
                        </p>
                        <br />
                        <p>
                          Trained architectural professionals with licensure in New York State;
                          the studio has been exploring how interests in public space can
                          operate at multiple scales through experimental projects since 2012.
                          Haferd and Knapp were winners of the inaugural 2012 Folly competition
                          held by the Architectural League of New York and Socrates Sculpture
                          Park. Their work has been exhibited at various institutions including
                          Storefront for Art & Architecture.
                        </p>
                      </article>
                    ) : null}
                  </div>
                  <div
                    className={`image mobile-image ${img.node.is_bio ? 'bio-container' : ''}`}
                    style={{
                      backgroundImage: 'url(' + img.node.mobile_image + ')',
                    }}
                  >
                    {img.node.is_bio ? (
                      <article>
                        <p>K Brandt Knapp & Jerome W Haferd,</p>
                        <p>collaborators since 2008</p>
                        <br />
                        <p>
                          BRANDT : HAFERD is a Harlem-based architecture and design studio led
                          by Jerome W Haferd & K Brandt Knapp. Their body of work includes
                          academic research and a range of built projects - from the domestic to
                          the workplace to the urban. Performance and Play, Abstract vs. Built
                          Form, Nature and Territory are some of the interests explored in the
                          practice.
                        </p>
                        <br />
                        <p>
                          Haferd (M.Arch, Yale) has worked in the offices of OMA and Bernard
                          Tschumi Architects, where for years he was involved in numerous
                          projects in the U.S., Asia, and Europe. He teaches at Columbia GSAPP,
                          NJIT and Pratt Institute.
                        </p>
                        <br />
                        <p>
                          Knapp (M.Arch, Yale) has worked in the offices of Joeb Moore &
                          Partners, and Richard Meier & Partners, where she designed and led
                          institutional and residential projects in the U.S. and abroad. She
                          teaches at Columbia GSAPP, PennDesign, NJIT and Pratt Institute.{' '}
                        </p>
                        <br />
                        <p>
                          Trained architectural professionals with licensure in New York State;
                          the studio has been exploring how interests in public space can
                          operate at multiple scales through experimental projects since 2012.
                          Haferd and Knapp were winners of the inaugural 2012 Folly competition
                          held by the Architectural League of New York and Socrates Sculpture
                          Park. Their work has been exhibited at various institutions including
                          Storefront for Art & Architecture.
                        </p>
                      </article>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </SlickSlider>
          <div
            className="down-arrow-container"
            dangerouslySetInnerHTML={{
              __html: this.renderDownArrow(titleColor),
            }}
          ></div>
        </div>
        <Modal
          open={open}
          onClose={this._onCloseModal}
          showCloseIcon={false}
          classNames={{ modal: 'pdf_modal' }}
          center
        >
          <embed
            rel="preload"
            type="application/pdf"
            src={
              '/images/pdf_booklet.pdf#page=' +
              pdfPageIndex +
              '&view=Fit&scrollbar=0&toolbar=0&navpanes=0'
            }
            width="100%"
            height="100%"
            object-fit="contain"
          ></embed>
        </Modal>
      </div>
    )
  }
}

export default Slider
