import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../styles/reset.css'
import '../styles/layout.less'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
          link={[
            {
              rel: 'stylesheet',
              type: 'text/css',
              charset: 'UTF-8',
              href:
                'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css',
            },
            {
              rel: 'stylesheet',
              type: 'text/css',
              charset: 'UTF-8',
              href:
                'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css',
            },
            {
              rel: 'stylesheet',
              href: 'https://fonts.googleapis.com/css?family=Tenor+Sans',
            },
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: '/images/favicon.png',
            },
          ]}
          script={[
            {
              src: 'https://identity.netlify.com/v1/netlify-identity-widget.js',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div>{children}</div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
